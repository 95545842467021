import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { summarize } from '~/utils/seo'
import { getAllMicrocms } from '~/utils/staticQuery'
import Typography from '~/components/atoms/Typography'
import Button from '~/components/atoms/Button'
import Tag from '~/components/atoms/Tag'
import Popup from '~/components/atoms/Popup'
import DotIcon from '~/components/molecules/DotIcon'
import DoubleButton from '~/components/molecules/DoubleButton'
import TopSlider from '~/components/molecules/TopSlider'
import Supervisor from '~/components/molecules/Supervisor'
import NewsList from '~/components/organisms/NewsList'
import ServiceList from '~/components/organisms/ServiceList'
import CVDualALink from '~/components/molecules/CVDualALink'
import SentoList from '~/components/organisms/Sento/SentoList'

import {
    feature_icon_list,
    feature_icon_card,
    feature_icon_img_box,
    pc_hide,
    sp_hide,
    feature_icon_section,
    contents_bg,
    contents_text,
    category,
    description_box,
    description_text,
    contents_bg_2,
    popup,
    list_x_scroll,
    twitter_card,
    heading_box,
    story_section,
    section,
    contents_box,
    card_xl,
    card_xl_even,
    column2,
    column2_reverse,
    story_link,
    list__yx,
    text_link,
    sns_link,
    sns_title,
    sns_heading_box,
    sns_card,
    contents_description,
    gallery_img,
    media_logo_l,
    media_logo_m,
    media_list,
    img,
    double_btn,
    story_blur,
    sns_card_left,
    twitter_card_right,
    feature_icon_text,
    slider,
    slider_item,
    hero_container,
    hero_title,
    hero_top_left,
    hero_subtitle,
    hero_top_right,
    text_shadow,
    news,
    tags,
    voice_title,
    story_title,
    story_body,
    support,
    support_body,
    sns_section_title,
    gallery__title,
    hero_button,
    hero_bottom_left,
    hero_tag,
    container,
    header_bottom_btn,
    supervisor_contents_box,
    supervisor,
    bg_primary, service_list,
    sento
} from './styles'

import MINERAL from '~/images/mineral.svg'
import ONSEN_MARK from '~/images/onsen_mark.svg'
import APP from '~/images/app.svg'

const IndexPage = () => {
    const {
        sbn_01,
        sbn_02,
        sbn_03,
        sbn_04,
        sbn_05,
        sbn_06,
        sbn_07,
        sbn_08,
        img_01,
        img_02,
        bg_story,
        slide_1,
        slide_2,
        slide_3,
        getnavi_logo,
        anan_logo,
        vogue_logo,
        techable_logo,
        signal_logo
    } = useStaticQuery<GatsbyTypes.TopQuery>(graphql`
		query Top {
			sbn_01: file(relativePath: {eq: "sbn_01.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    sbn_02: file(relativePath: {eq: "sbn_02.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    sbn_03: file(relativePath: {eq: "sbn_03.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    sbn_04: file(relativePath: {eq: "sbn_04.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    sbn_05: file(relativePath: {eq: "sbn_05.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    sbn_06: file(relativePath: {eq: "sbn_06.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    sbn_07: file(relativePath: {eq: "sbn_07.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    sbn_08: file(relativePath: {eq: "sbn_08.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    getnavi_logo: file(relativePath: {eq: "logos/get.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    techable_logo: file(relativePath: {eq: "logos/logo-grd_1.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    signal_logo: file(relativePath: {eq: "logos/logo_blk.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    anan_logo: file(relativePath: {eq: "logos/anan.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    vogue_logo: file(relativePath: {eq: "logos/voguejapan_20180202_001.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    img_01: file(relativePath: {eq: "img_01.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 1900) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    img_02: file(relativePath: {eq: "img_02.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 1900) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    bg_story: file(relativePath: {eq: "bg_story.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 1900) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    slide_1: file(relativePath: {eq: "slide/slide-1.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 1900) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    slide_2: file(relativePath: {eq: "slide/slide-2.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 1900) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    slide_3: file(relativePath: {eq: "slide/slide-3.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 1900) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		}
	`)

    return (
        <>
            <div css={hero_container}>
                <TopSlider css={slider}>
                    <BackgroundImage
                        fluid={slide_1.childImageSharp.fluid}
                        css={slider_item}
                    />
                    <BackgroundImage
                        fluid={slide_2.childImageSharp.fluid}
                        css={slider_item}
                    />
                    <BackgroundImage
                        fluid={slide_3.childImageSharp.fluid}
                        css={slider_item}
                    />
                </TopSlider>
                <div css={hero_top_left}>
                    <Typography component='h1' css={hero_title} color='textPrimary'>入浴剤と<br css={pc_hide}/>専用アプリで<br/>すべてが新しい<br/>おふろ体験。</Typography>
                    <Link to='/pages/app'>
                        <Button css={hero_button}>アプリを入手する</Button>
                    </Link>
                    {/* <DoubleButton
                        css={hero_button}
                        text_left={'入浴剤を購入する'}
                        link_left={'/collections/dailymineral'}
                        text_right={'アプリを入手する'}
                        link_right={'/pages/app'}
                    /> */}
                </div>
                <div css={hero_top_right}>
                    <Typography css={[hero_subtitle, text_shadow]}>入浴を通して毎日豊かに</Typography>
                </div>
                <div css={hero_bottom_left}>
                    <Tag css={hero_tag}>医薬部外品</Tag>
                    <Tag css={hero_tag}>医師監修のアプリ</Tag>
                </div>
            </div>

            <section css={container}>
                <Link to='/pages/app'>
                    <Button css={[double_btn, header_bottom_btn]}>アプリを入手する</Button>
                </Link>
                {/* <DoubleButton
                    css={[double_btn, header_bottom_btn]}
                    text_left={'入浴剤を購入する'}
                    link_left={'/collections/dailymineral'}
                    text_right={'アプリを入手する'}
                    link_right={'/pages/app'}
                /> */}
            </section>

            {/* 帯 */}
            <section css={feature_icon_section}>
                <ul css={feature_icon_list}>
                    <li css={feature_icon_card}>
                        <figure>
                            <div css={feature_icon_img_box}>
                                <img src={MINERAL} alt='' />
                            </div>
                            <figcaption>
                                <Typography variant='subtitle2' component='p' css={feature_icon_text}>
                                    別府温泉の<br css={pc_hide} />成分を<br css={sp_hide} />配合した<br css={pc_hide} />薬用入浴剤
                                </Typography>
                            </figcaption>
                        </figure>
                    </li>
                    <li css={feature_icon_card}>
                        <figure>
                            <div css={feature_icon_img_box}>
                                <img src={ONSEN_MARK} alt='' />
                            </div>
                            <figcaption>
                                <Typography variant='subtitle2' component='p' css={feature_icon_text}>
                                    入浴剤とアプリ<br css={pc_hide} />で<br css={sp_hide} />生まれる<br css={pc_hide} />新体験
                                </Typography>
                            </figcaption>
                        </figure>
                    </li>
                    <li css={feature_icon_card}>
                        <figure>
                            <div css={feature_icon_img_box}>
                                <img src={APP} alt='' />
                            </div>
                            <figcaption>
                                <Typography variant='subtitle2' component='p' css={feature_icon_text}>
                                    温泉療法専門医<br css={pc_hide} />が<br css={sp_hide} />監修した<br css={pc_hide} />入浴アプリ
                                </Typography>
                            </figcaption>
                        </figure>
                    </li>
                </ul>
            </section>
            {/* 入浴剤の説明 */}
            <section css={section}>
                <ul>
                    <li css={card_xl_even}>
                        <article>
                            <figure css={column2_reverse}>
                                <BackgroundImage Tag='div' fluid={img_02.childImageSharp.fluid} css={contents_bg_2} />
                                <Popup css={popup}>無料<br />アプリ</Popup>
                                <figcaption css={contents_text}>
                                    <Typography css={category} variant='subtitle1' component='h3'
                                                color='textSecondary'>アプリ</Typography>
                                    <Typography color='textPrimary' variant='h3' component='p'>
                                        <Typography variant='h3' component='span'
                                                    color='secondary'>お風呂＆リラックス体験</Typography>をアプリが徹底サポート。</Typography>
                                    <Typography css={contents_description} variant='h5'
                                                component='p'>専門医が監修した入浴レシピで自宅のお風呂が「スパ空間」へ。</Typography>
                                    <ol css={description_box}>
                                        <Typography css={description_text} variant='body2'
                                                    component='li'><DotIcon />リラックスと温浴効果を高める入浴タイマー</Typography>
                                        <Typography css={description_text} variant='body2'
                                                    component='li'><DotIcon />正しい入浴方法がわかる入浴レシピ</Typography>
                                        <Typography css={description_text} variant='body2'
                                                    component='li'><DotIcon />ヒーリング効果を高める環境音</Typography>
                                    </ol>
                                    <div css={tags}>
                                        <Tag>無料アプリ</Tag>
                                        <Tag>医師監修アプリ</Tag>
                                    </div>
                                    <Link to='/pages/app'>
                                        <Button css={text_link}>詳しく見る</Button>
                                    </Link>
                                </figcaption>
                            </figure>
                        </article>
                    </li>
                </ul>
            </section>
            {/* アプリの監修 */}
            <section css={[section, supervisor]}>
                <Supervisor css={supervisor_contents_box}/>
            </section>
            {/* 銭湯スライダー */}            
             <section css={[section, sento]}>
                <SentoList bathhousesSummary={[]}/>
            </section>
            {/* お客様の声 */}
            <section css={section}>
                <div css={heading_box}>
                    <Typography variant='subtitle1' component='p' color='textSecondary'>
                        Voice
                    </Typography>
                    <Typography css={voice_title} variant='h2' component='h2' color='textPrimary'>お客様の声</Typography>
                </div>
                <ul css={list_x_scroll}>
                    <li css={twitter_card}>
                        <article>
                            <blockquote className='twitter-tweet' data-theme='light'>
                                <p lang='ja' dir='ltr'>入浴剤ヲタクが<br />
                                    BARTH超えの入浴剤と自信を持ってオススメしたいプロダクトとついに出会えました。<br />
                                    <br />
                                    これはヘビロテ確定です。Makuake以降はどうやって買えばいいのか調べておこう。<br />
                                    <br />
                                    onsenって名前もいいし、薬用入浴剤だし、風呂上がりの肌着の通りが全然違って、本当に感動レベル。<br />
                                    <br />
                                    PRじゃないよ <a href='https://t.co/sipqFYWbEs'>pic.twitter.com/sipqFYWbEs</a>
                                </p>
                                &mdash; D2C研究家 /// 海老原 🔥🔥(ここ最近のイチオシD2Cは「onsen*」です) (@d2c_maestro) <a
                                href='https://twitter.com/d2c_maestro/status/1317097863105576960?ref_src=twsrc%5Etfw'>October
                                16, 2020</a></blockquote>
                        </article>
                    </li>
                    <li css={twitter_card_right}>
                        <article>
                            <blockquote className="twitter-tweet">
                                <p lang="ja" dir="ltr">今日は家風呂です。<br />
                                    DAILY MINERAL 、久々にヒバです。<br />
                                    菩提樹ヴィヒタも入れてみた😊 <a href="https://t.co/Lap7104Tnt">pic.twitter.com/Lap7104Tnt</a>
                                </p>
                                &mdash; löyly_girl (@LoylyG) <a href="https://twitter.com/LoylyG/status/1429086817412730885?ref_src=twsrc%5Etfw">August 21, 2021</a>
                            </blockquote>
                        </article>
                    </li>
                </ul>
            </section>
            {/* ニュース・メディア掲載 */}
            <section css={section}>
                <NewsList css={news}/>
                {/* メディアリスト */}
                <ul css={media_list}>
                    <li css={media_logo_m}><Img fluid={vogue_logo.childImageSharp.fluid} /></li>
                    <li css={media_logo_m}><Img fluid={anan_logo.childImageSharp.fluid} alt='' /></li>
                    <li css={media_logo_m}><Img fluid={getnavi_logo.childImageSharp.fluid} /></li>
                    <li css={media_logo_m}><Img fluid={techable_logo.childImageSharp.fluid} alt='' /></li>
                    <li css={media_logo_m}><Img fluid={signal_logo.childImageSharp.fluid} /></li>
                </ul>
            </section>
            {/* Story - Onsen */}
            <BackgroundImage Tag='section' fluid={bg_story.childImageSharp.fluid} css={story_section}>
                <div css={[story_blur, section]}>
                    <div css={heading_box}>
                        <Typography variant='subtitle1' component='p' color='textSecondary'>
                            Story - Onsen*
                        </Typography>
                        <Typography css={story_title} variant='h2' component='h2' color='textPrimary'>Onsen*について</Typography>
                    </div>
                    <article css={story_body}>
                        <Typography variant='body1' component='p'>
                            スマートフォンやインターネットで<br />
                            情報に溢れる現代。<br /><br />
                            気づかないうちにストレスがたまり、<br />
                            いつも何かに刺激されています。<br /><br />
                            必要なのはストレスから解放され<br />
                            自分と向き合う上質な時間。<br /><br />
                            Onsen*は、自分と向き合う<br />
                            あなたを応援します。
                        </Typography>
                        <Link to='/story'>
                            <Button css={story_link}>詳しく見る</Button>
                        </Link>
                    </article>
                </div>
            </BackgroundImage>
            {/* ギャラリー */}
            <section css={section}>
                <div css={heading_box}>
                    <Typography variant='subtitle1' component='p' color='textSecondary'>
                        Gallery
                    </Typography>
                    <Typography css={gallery__title} variant='h2' component='h2' color='textPrimary'>ギャラリー</Typography>
                </div>
                <article css={list_x_scroll}>
                    <ul css={gallery_img}>
                        <li><Img fluid={sbn_01.childImageSharp.fluid} /></li>
                        <li><Img fluid={sbn_02.childImageSharp.fluid} /></li>
                    </ul>
                    <ul css={gallery_img}>
                        <li><Img fluid={sbn_03.childImageSharp.fluid} /></li>
                        <li><Img fluid={sbn_04.childImageSharp.fluid} /></li>
                    </ul>
                    <ul css={gallery_img}>
                        <li><Img fluid={sbn_05.childImageSharp.fluid} /></li>
                        <li><Img fluid={sbn_06.childImageSharp.fluid} /></li>
                    </ul>
                    <ul css={gallery_img}>
                        <li><Img fluid={sbn_07.childImageSharp.fluid} /></li>
                        <li><Img fluid={sbn_08.childImageSharp.fluid} /></li>
                    </ul>
                </article>
            </section>
            {/* 今すぐ体験しよう */}
            <section css={bg_primary}>
                <ServiceList />
            </section>
            {/* サポート */}
            <div css={[support, section]}>
                <div css={heading_box}>
                    <Typography variant='h2' component='h2' color='textPrimary'>
                        サポートが<br css={pc_hide} />必要ですか？
                    </Typography>
                    <Typography css={support_body} variant='body1' component='p'>
                            よくある質問を参考にしたり、<br css={sp_hide} />サポートフォームからお問い合わせください。
                    </Typography>
                    <CVDualALink
                        css={double_btn}
                        left={{
                            text: 'よくある質問を見る',
                            link: 'https://on-sen.zendesk.com/hc/ja'
                        }}
                        right={{
                            text: '問い合わせる',
                            link: 'https://on-sen.zendesk.com/hc/ja/requests/new'
                        }}
                    />
                </div>
            </div>
            {/* SNS */}
            <section css={section}>
                <div css={heading_box}>
                    <Typography variant='subtitle1' component='p' color='textSecondary'>
                        SNS
                    </Typography>
                    <Typography css={sns_section_title} variant='h2' component='h2' color='textPrimary'>公式SNS</Typography>
                </div>
                <ul css={list__yx}>
                    <li css={sns_card}>
                        <div css={sns_heading_box}>
                            <Typography css={sns_title} variant='h3' component='h3'
                                        color='textPrimary'>Twitter</Typography>
                            <a css={sns_link} href='https://twitter.com/onsen_cure' target='_blank' rel='noopener noreferrer'>
                                <Typography variant='subtitle2' component='p' color='primary'>すべて見る</Typography>
                            </a>
                        </div>
                        <article css={contents_box}>
                            <blockquote className="twitter-tweet">
                                <p lang="ja" dir="ltr">♨︎【香り別】入浴剤の選び方♨︎<br/><br/>〈気分〉<br/>入浴後スッと眠りにつきたい<br/><br/>〈香りタイプ〉<br/>樹脂系<br/><br/>〈代表的な香り〉<br/>フランキンセンス、ベンゾイン、ミルラ<br/><br/>重厚感を感じる甘い香りが特徴。自然の香りに包まれながらお湯に浸かって、心地よい眠りにつきましょう✨ <a
                                href="https://t.co/KpdAEZ2umc">pic.twitter.com/KpdAEZ2umc</a></p>&mdash; Onsen* |
                                お風呂をライフスタイルに (@onsen_cure)
                                <a href="https://twitter.com/onsen_cure/status/1398249457670578177?ref_src=twsrc%5Etfw">May 28, 2021</a>
                            </blockquote>
                        </article>
                    </li>
                    <li css={sns_card_left}>
                        <div css={sns_heading_box}>
                            <Typography css={sns_title} variant='h3' component='h3'
                                        color='textPrimary'>note</Typography>
                            <a css={sns_link} href='https://note.com/onsen_cure/' target='_blank' rel='noopener noreferrer'>
                                <Typography variant='subtitle2' component='p' color='primary'>すべて見る</Typography>
                            </a>
                        </div>
                        <article css={contents_box}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `
									<iframe class="note-embed" src="https://note.com/embed/notes/n7bcad1df054b" style="border: 0; display: block; max-width: 99%; width: 494px; padding: 0px; margin: 10px 0px; position: static; visibility: visible;" height="400"></iframe><script async src="https://note.com/scripts/embed.js" charset="utf-8"></script>
									`
                                }}
                            />
                        </article>
                    </li>
                </ul>
            </section>
        </>
    )
}

export default IndexPage
